<template>
  <b-card no-body>
    <vue-apex-charts
      type="radar"
      height="1000"
      :options="radarData.chartOptions"
      :series="radarData.series"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'RadarChart',
  components: {
    BCard,
    VueApexCharts,
  },
  computed: {
    radarData() {
      const data = this.$store.getters['sdgResult/radarData']
      return {
        series: [
          {
            name: this.$i18n.t('Etki Analizi'),
            data: data.data,
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
            dropShadow: {
              enabled: false,
              blur: 8,
              left: 0,
              top: 1,
              opacity: 0.2,
            },
          },
          legend: {
            show: true,
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          yaxis: {
            show: true,
            min: 0,
            max: 100,
          },
          colors: ['#FF7032'],
          xaxis: {
            categories: data.categories,
            labels: {
              show: true,
              style: {
                fontSize: '14px',
                fontWeight: 'Bold',
                fontFamily: 'Montserrat',
                colors: [
                  '#ff0000', // SDG1: Yoksullukla Mücadele - Kırmızı
                  '#50a150', // SDG2: Açlıkla Mücadele - Yeşil
                  '#0000ff', // SDG3: İyi Sağlık ve Refah - Mavi
                  '#7c7c00', // SDG4: Nitelikli Eğitim - Sarı
                  '#9b009b', // SDG5: Toplumsal Cinsiyet Eşitliği - Pembe
                  '#00abab', // SDG6: Temiz Su ve Sanitasyon - Turkuaz
                  '#ff8000', // SDG7: Uygun Fiyatlı ve Temiz Enerji - Turuncu
                  '#8000ff', // SDG8: İyi İş ve Ekonomik Büyüme - Mor
                  '#0080ff', // SDG9: Sanayi, İnovasyon ve Altyapı - Açık Mavi
                  '#ff0080', // SDG10: Azaltılmış Eşitsizlikler - Koyu Pembe
                  '#008000', // SDG11: Sürdürülebilir Şehirler ve Topluluklar - Yeşil
                  '#800000', // SDG12: Sorumlu Tüketim ve Üretim - Kahverengi
                  '#ff8000', // SDG13: İklim Eylemi - Turuncu
                  '#51a87c', // SDG14: Su Altı Hayatı - Açık Yeşil
                  '#804000', // SDG15: Karasal Hayat - Koyu Kahverengi
                  '#800080', // SDG16: Barış, Adalet ve Güçlü Kurumlar - Mor
                  '#808080', // SDG17: Amaçlara Yönelik Ortaklıklar - Gri
                ],
              },
            },
          },
          fill: {
            opacity: [0.5],
          },
          stroke: {
            show: false,
            width: 0,
          },
          markers: {
            size: 4,
          },
          tooltip: {
            y: {
              formatter(val) {
                return `%${val}`
              },
            },
          },
          grid: {
            show: false,
          },
          dataLabels: {
            enabled: true,
            background: {
              enabled: true,
              borderRadius: 2,
            },
          },
        },
      }
    },
  },
}
</script>
