<template>
  <b-card no-body>
    <b-table-simple
      bordered
      responsive
    >
      <b-thead>
        <b-tr>
          <b-th colspan="2">
            {{ $t('Rapor Detayı') }}
          </b-th>
          <b-th class="width-200 text-nowrap text-center">
            SEÇİM
          </b-th>
          <b-th class="width-200 text-nowrap text-center">
            SKOR
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-for="(item,key) in detailData">
          <b-tr :key="item.id">
            <b-td
              class="width-50 text-nowrap text-primary font-weight-bold"
              :rowspan="item.items.length + 1"
            >
              SDG{{ key + 1 }}
            </b-td>
            <b-td
              colspan="2"
              class="text-primary font-weight-bold"
            >
              {{ item.title }}
            </b-td>
            <b-td class="text-center font-weight-bold">
              %{{ item.percent_score | toNumber }}
            </b-td>
          </b-tr>
          <b-tr
            v-for="question in item.items"
            :key="'q_' + question.id"
          >
            <b-td>{{ question.title }}</b-td>
            <b-td class="text-center">
              {{ question.option }}
            </b-td>
            <b-td class="text-center">
              {{ question.score }}
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
  </b-card>
</template>

<script>
import {
  BCard, BTableSimple, BThead, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'

export default {
  name: 'ReportDetail',
  components: {
    BCard,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  computed: {
    detailData() {
      return this.$store.getters['sdgResult/detail']
    },
  },
}
</script>
