<template>
  <b-row>
    <b-col
      cols="12"
      md="6"
    >
      <radial-bar-chart />
    </b-col>
    <b-col
      cols="12"
      md="6"
    >
      <donut-chart />
    </b-col>
    <b-col cols="12">
      <radar-chart />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import RadarChart from '@/views/Admin/Startup_applies/Sgd/ImpactAnalysis/RadarChart.vue'
import RadialBarChart from '@/views/Admin/Startup_applies/Sgd/ImpactAnalysis/RadialBarChart.vue'
import DonutChart from '@/views/Admin/Startup_applies/Sgd/ImpactAnalysis/DonutChart.vue'

export default {
  name: 'ImpactAnalysis',
  components: {
    BRow,
    BCol,
    RadarChart,
    RadialBarChart,
    DonutChart,
  },
}
</script>
