<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title class="mb-sm-0 mb-1">
        SGD Ortalama Performans
      </b-card-title>
    </b-card-header>
    <b-card-body v-if="radialBarData.series.length">
      <vue-apex-charts
        type="radialBar"
        height="400"
        :options="radialBarData.chartOptions"
        :series="radialBarData.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardBody, BCardHeader,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

const chartColors = {
  donut: {
    series1: '#E74C3C',
    series2: '#27AE60',
    series3: '#27AE60',
    series4: '#3498DB',
    series5: '#FFA1A1',
  },
}
export default {
  name: 'RadialBarChart',
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardBody,
    BCardHeader,
  },
  computed: {
    radialBarData() {
      const data = this.$store.getters['sdgResult/radialBarData']
      return {
        series: data.series,
        chartOptions: {
          colors: [chartColors.donut.series1, chartColors.donut.series2, chartColors.donut.series4],
          plotOptions: {
            radialBar: {
              offsetY: 0,
              startAngle: 0,
              endAngle: 270,
              hollow: {
                margin: 5,
                size: '40%',
                background: 'transparent',
                image: undefined,
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          legend: {
            show: true,
            floating: true,
            fontSize: '16px',
            position: 'left',
            offsetX: 160,
            offsetY: 20,
            labels: {
              useSeriesColors: true,
            },
            markers: {
              size: 0,
            },
            formatter(seriesName, opts) {
              return `${seriesName}:  ${opts.w.globals.series[opts.seriesIndex]}`
            },
            itemMargin: {
              vertical: 3,
            },
          },
          stroke: {
            lineCap: 'round',
          },
          labels: data.labels,
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      }
    },
  },
}
</script>
