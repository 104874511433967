<template>
  <div>
    <b-tabs
      pills
      align="left"
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="PieChartIcon" />
          <span>{{ $t('Etki Analizi') }}</span>
        </template>
        <impact-analysis />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="EyeIcon" />
          <span>{{ $t('Rapor Özeti') }}</span>
        </template>
        <report-summary />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>{{ $t('Rapor Detayı') }}</span>
        </template>
        <report-detail />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTab, BTabs } from 'bootstrap-vue'
import ImpactAnalysis from '@/views/Admin/Startup_applies/Sgd/ImpactAnalysis.vue'
import ReportSummary from '@/views/Admin/Startup_applies/Sgd/ReportSummary.vue'
import ReportDetail from '@/views/Admin/Startup_applies/Sgd/ReportDetail.vue'

export default {
  name: 'Sgd',
  components: {
    BTabs,
    BTab,
    ImpactAnalysis,
    ReportSummary,
    ReportDetail,
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('sdgResult/getData', this.$route.params.id)
    },
  },
}
</script>
